import { Limits } from 'enum';
import { Room } from 'matrix-js-sdk';
import { Preset, Visibility } from 'matrix-js-sdk/lib/@types/partials';
import { ICreateRoomOpts } from 'matrix-js-sdk/lib/@types/requests';
import { matrixClient } from './matrix-client';
import { RoomMembership } from './types';

type CreateRoomOptions = {
    name: string;
    topic?: string;
    isPublic?: boolean;
    isEncrypted?: boolean;
    isDirect?: boolean;
};

type CreateDirectChatOptions = {
    userId: string;
};

type GetInviteListOptions = {
    keyword: string;
    page: number;
};
type GetInviteListPayload = { userIds: string[]; hasNextPage: boolean };

export type GetInviteListFn = (options: GetInviteListOptions) => Promise<GetInviteListPayload>;

type InviteUserToRoomOptions = {
    roomId: string;
    userIds: string[];
};

type JoinRoomOptions = {
    roomId: string;
};

type LeaveRoomOptions = {
    roomId: string;
};

type KickFromRoomOptions = {
    roomId: string;
    userId: string;
};

const addRoomToMDirect = (roomId: string, userId: string) => {
    const mDirectsEvent = matrixClient.getAccountData('m.direct');
    let userIdToRoomIds = {};

    if (typeof mDirectsEvent !== 'undefined') userIdToRoomIds = mDirectsEvent.getContent();

    // remove it from the lists of any others users
    // (it can only be a DM room for one person)
    Object.keys(userIdToRoomIds).forEach((thisUserId) => {
        const roomIds = userIdToRoomIds[thisUserId];

        if (thisUserId !== userId) {
            const indexOfRoomId = roomIds.indexOf(roomId);
            if (indexOfRoomId > -1) {
                roomIds.splice(indexOfRoomId, 1);
            }
        }
    });

    // now add it, if it's not already there
    if (userId) {
        const roomIds = userIdToRoomIds[userId] || [];
        if (roomIds.indexOf(roomId) === -1) {
            roomIds.push(roomId);
        }
        userIdToRoomIds[userId] = roomIds;
    }

    return matrixClient.setAccountData('m.direct', userIdToRoomIds);
};

export class RoomStore {
    static createRoom = async (options: CreateRoomOptions): Promise<string> => {
        const opts: ICreateRoomOpts = {
            name: options.name,
            topic: options.topic,
            visibility: options.isPublic ? Visibility.Public : Visibility.Private,
            is_direct: options.isDirect,
            initial_state: [],
        };

        if (!options.isPublic && options.isEncrypted) {
            opts.initial_state?.push({
                type: 'm.room.encryption',
                state_key: '',
                content: {
                    algorithm: 'm.megolm.v1.aes-sha2',
                },
            });
        }

        const { room_id } = await matrixClient.createRoom(opts);

        return room_id;
    };

    static createDirectChat = async (options: CreateDirectChatOptions): Promise<string> => {
        const opts: ICreateRoomOpts = {
            is_direct: true,
            visibility: Visibility.Private,
            preset: Preset.TrustedPrivateChat,
            invite: [options.userId],
        };

        const { room_id } = await matrixClient.createRoom(opts);

        await addRoomToMDirect(room_id, options.userId);

        return room_id;
    };

    static getInviteList: GetInviteListFn = async () => {
        return new Promise(() => {
            //
        });
    };

    static inviteUserToRoom = async (options: InviteUserToRoomOptions): Promise<void> => {
        const { roomId, userIds } = options;

        if (!roomId || userIds.length === 0) {
            return;
        }
        for await (const userId of userIds) {
            matrixClient.invite(roomId, userId);
        }
    };

    static joinRoom = async (options: JoinRoomOptions): Promise<void> => {
        const { roomId } = options;
        const room = await matrixClient.getRoom(roomId);

        await matrixClient.joinRoom(roomId);

        if (RoomStore.isDMInvite(room)) {
            const myUserId = await matrixClient.getUserId();
            await addRoomToMDirect(roomId, myUserId);
        }

        if (!roomId) {
            return;
        }
    };

    static leaveRoom = async (options: LeaveRoomOptions): Promise<void> => {
        const { roomId } = options;

        if (!roomId) {
            return;
        }

        await matrixClient.leave(roomId);
    };

    static kickFromRoom = async (options: KickFromRoomOptions): Promise<void> => {
        const { roomId, userId } = options;

        if (!roomId) {
            return;
        }

        await matrixClient.kick(roomId, userId);
    };

    static getAllRooms = (): {
        directs: Room[];
        rooms: Room[];
        spaces: Room[];
        inviteDirects: Room[];
        inviteRooms: Room[];
        inviteSpaces: Room[];
    } => {
        const mDirects = RoomStore.getMDirects();
        const directs: Room[] = [];
        const rooms: Room[] = [];
        const spaces: Room[] = [];

        const inviteDirects: Room[] = [];
        const inviteRooms: Room[] = [];
        const inviteSpaces: Room[] = [];

        const addRoom = (list: Room[], room: Room) => {
            const exists = list.some((r) => r.roomId === room.roomId);
            if (!exists) {
                list.push(room);
            }
        };

        matrixClient.getRooms().forEach((room) => {
            const { roomId } = room;

            if (room.getMyMembership() === RoomMembership.Invite) {
                if (RoomStore.isDMInvite(room)) {
                    addRoom(inviteDirects, room);
                } else if (room.isSpaceRoom()) {
                    addRoom(inviteSpaces, room);
                } else {
                    addRoom(inviteRooms, room);
                }
            }

            if (room.getMyMembership() !== RoomMembership.Join) {
                return;
            }

            if (mDirects.has(roomId)) {
                addRoom(directs, room);
            } else if (room.isSpaceRoom()) {
                addRoom(spaces, room);
            } else {
                addRoom(rooms, room);
            }
        });

        rooms.sort((a, b) => b.getLastActiveTimestamp() - a.getLastActiveTimestamp());
        return { directs, rooms, spaces, inviteDirects, inviteRooms, inviteSpaces };
    };

    static getPublicRooms = async (keyword, since?: string | undefined) => {
        const res = await matrixClient.publicRooms({
            include_all_networks: true,
            since,
            filter: {
                generic_search_term: keyword,
            },
            limit: Limits.PUBLIC_ROOMS_SEARCH_LIMIT,
        });
        return { chunk: res.chunk, nextBatch: res.next_batch };
    };

    // #region Private methods
    private static getMDirects = (): Set<string> => {
        const mDirects = new Set<string>();
        const mDirect: { [userId: string]: string[] } = matrixClient.getAccountData('m.direct')?.getContent();
        if (mDirect) {
            Object.keys(mDirect).forEach((direct) => {
                mDirect[direct].forEach((directId) => mDirects.add(directId));
            });
        }

        return mDirects;
    };

    private static isDMInvite = (room: Room): boolean => {
        const me = room.getMember(matrixClient.getUserId());
        if (!me) {
            throw new Error('Unauthorized');
        }

        const myEventContent = me.events.member?.getContent();

        if (!myEventContent) {
            return false;
        }

        return myEventContent.membership === RoomMembership.Invite && myEventContent.is_direct;
    };

    static uploadAvatar = (avatar) => {
        matrixClient.setAvatarUrl(avatar);
    };
    // #endregion
}
