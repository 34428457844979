import { Box, Typography } from '@material-ui/core';
import { matrixClient } from 'chat/context/matrix/matrix-client';
import colorMXID from 'chat/context/matrix/utils/colorMXID';
import { isMedia } from 'chat/context/matrix/utils/matrixUtil';
import { useChatState } from 'chat/context/store';
import { DateTime } from 'luxon';
import { IContent, MatrixEvent, RoomMember } from 'matrix-js-sdk';
import React, { useMemo } from 'react';
import { SenderAvatar } from '../sender-avatar';
import { useStyles } from './message.style';
import * as Media from '../media';

export type MessageProps = {
    message: MatrixEvent;
    prevEvent?: MatrixEvent;
};

const MessageComponent: React.FC<MessageProps> = ({ message, prevEvent }) => {
    const classes = useStyles({ replyBorderColor: '#ccc' });

    const {
        pageStartIds,
        eventEdits: { [message.getId()]: edits },
    } = useChatState();

    const contentOnly = useMemo(() => {
        let contentOnly = false;

        const getAvatarUrl = (member: RoomMember) =>
            member.getAvatarUrl(matrixClient.baseUrl, 36, 36, 'crop', true, true);

        if (prevEvent && !pageStartIds.includes(message.getId())) {
            if (
                prevEvent.getType() === 'm.room.message' &&
                prevEvent.sender.userId === message.sender.userId &&
                getAvatarUrl(prevEvent.sender) === getAvatarUrl(message.sender)
            ) {
                const prevEventDate = DateTime.fromJSDate(prevEvent.getDate() || new Date());
                const currEventDate = DateTime.fromJSDate(message.getDate() || new Date());
                const diffInMinutes = currEventDate.diff(prevEventDate, 'minute').minutes;
                if (diffInMinutes <= 5) {
                    contentOnly = true;
                }
            }
        }

        return contentOnly;
    }, [message, pageStartIds, prevEvent]);

    const isEdited = edits && edits.length > 0;

    const { sender } = message;
    const senderColor = useMemo(() => colorMXID(sender.userId), [sender.userId]);
    const senderName = sender.name || sender.userId;

    const date = DateTime.fromJSDate(message.getDate() || new Date()).toFormat('HH:mm');

    const content = message.getContent();

    const { body, format, formatted_body } = content['m.new_content'] || content;

    const isHtml = format === 'org.matrix.custom.html';

    return (
        <Box display="flex" className={classes.root}>
            <SenderAvatar sender={sender} empty={contentOnly} />
            <Box display="flex" flexDirection="column" className={classes.messageContent}>
                <Box
                    pb={0.5}
                    display={contentOnly ? 'none' : 'flex'}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography className={classes.senderName} style={{ color: senderColor }}>
                        {senderName}
                    </Typography>
                    <Typography className={classes.date}>{date}</Typography>
                </Box>

                {isMedia(message) ? (
                    getMediaContent(content)
                ) : (
                    <Box
                        className={classes.message}
                        dangerouslySetInnerHTML={isHtml ? { __html: formatted_body } : undefined}
                    >
                        {isHtml ? null : body}
                    </Box>
                )}
                {isEdited && (
                    <Box>
                        <Typography className={classes.date}>(edited)</Typography>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export const Message = React.memo(MessageComponent);

const getMediaContent = (content: IContent) => {
    const mediaMXC = content.url;
    const mediaLink = matrixClient.mxcUrlToHttp(mediaMXC);
    if (!mediaLink) return null;
    switch (content.msgtype) {
        case 'm.file':
            return <Media.File name={content.body} link={mediaLink} />;
        case 'm.image':
            return (
                <Media.Image
                    name={content.body}
                    width={typeof content.info?.w === 'number' ? content.info?.w : null}
                    height={typeof content.info?.h === 'number' ? content.info?.h : null}
                    link={mediaLink}
                />
            );
        case 'm.audio':
            return <Media.Audio name={content.body} link={mediaLink} type={content.info?.mimetype} />;
        case 'm.video':
            let thumbnailMXC = content.info?.thumbnail_url;
            const thumbnailLink = matrixClient.mxcUrlToHttp(thumbnailMXC);
            if (typeof thumbnailMXC === 'undefined') {
                thumbnailMXC = content.info?.thumbnail_file?.url || null;
            }
            return (
                <Media.Video
                    name={content.body}
                    link={mediaLink}
                    thumbnail={thumbnailMXC === null ? null : thumbnailLink}
                    width={typeof content.info?.w === 'number' ? content.info?.w : null}
                    height={typeof content.info?.h === 'number' ? content.info?.h : null}
                    type={content.info?.mimetype}
                />
            );
    }
};
