import { PowerLevels } from 'enum';
import { MatrixEvent, Room, RoomMember } from 'matrix-js-sdk';
import { EventType } from 'matrix-js-sdk/lib/@types/event';
import { matrixClient } from '../matrix-client';

const WELL_KNOWN_URI = '/.well-known/matrix/client';

async function getBaseUrl(homeserver: string): Promise<string> {
    const serverDiscoveryUrl = `https://${homeserver}${WELL_KNOWN_URI}`;
    try {
        const result = await fetch(serverDiscoveryUrl, { method: 'GET' });
        const data = await result.json();

        return data?.['m.homeserver']?.base_url;
    } catch (e) {
        throw new Error('Homeserver not found');
    }
}

function getUsername(userId: string): string {
    const user = matrixClient.getUser(userId);
    if (user === null) return userId;
    let username = user.displayName;
    if (typeof username === 'undefined') {
        username = userId;
    }
    return username;
}

function getUsernameOfRoomMember(roomMember: RoomMember): string {
    return roomMember.name || roomMember.userId;
}

async function isRoomAliasAvailable(alias: string): Promise<boolean> {
    try {
        const myUserId = matrixClient.getUserId();
        const myServer = myUserId.slice(myUserId.indexOf(':') + 1);
        const result = await matrixClient.resolveRoomAlias(alias);
        const aliasIsRegisteredOnMyServer = typeof result.servers.find((server) => server === myServer) === 'string';

        if (aliasIsRegisteredOnMyServer) return false;
        return true;
    } catch (e) {
        if ((e as any).errcode === 'M_NOT_FOUND') return true;
        if ((e as any).errcode === 'M_INVALID_PARAM') throw new Error(e as any);
        return false;
    }
}

function doesRoomHaveUnread(room: Room): boolean {
    const userId = matrixClient.getUserId();
    const readUpToId = room.getEventReadUpTo(userId);
    const supportEvents = ['m.room.message', 'm.room.encrypted', 'm.sticker'];

    if (
        room.timeline.length &&
        room.timeline[room.timeline.length - 1].sender &&
        room.timeline[room.timeline.length - 1].sender.userId === userId &&
        room.timeline[room.timeline.length - 1].getType() !== 'm.room.member'
    ) {
        return false;
    }

    for (let i = room.timeline.length - 1; i >= 0; i -= 1) {
        const event = room.timeline[i];

        if (event.getId() === readUpToId) return false;

        if (supportEvents.includes(event.getType())) {
            return true;
        }
    }
    return true;
}

function isMedia(matrixEvent: MatrixEvent): boolean {
    return (
        matrixEvent.getContent().msgtype === 'm.file' ||
        matrixEvent.getContent().msgtype === 'm.image' ||
        matrixEvent.getContent().msgtype === 'm.audio' ||
        matrixEvent.getContent().msgtype === 'm.video' ||
        matrixEvent.getType() === 'm.sticker'
    );
}
function isNumber(value: any): boolean {
    return typeof value === 'number' && isFinite(value);
}

const isMe = (member: RoomMember) => {
    return matrixClient.getUserId() === member.userId;
};

function getPowerLabel(powerLevel) {
    if (powerLevel > 9000) return PowerLevels.GOKU;
    if (powerLevel > 100) return PowerLevels.FOUNDER;
    if (powerLevel === 100) return PowerLevels.ADMIN;
    if (powerLevel >= 50) return PowerLevels.MOD;
    return null;
}

const hasSufficientPowerLevelFor = (action: string, powerLevel: number, room: Room) => {
    const powerLevelsEvent = room.currentState.getStateEvents(EventType.RoomPowerLevels, '');

    let powerLevels = {};
    if (powerLevelsEvent) {
        powerLevels = powerLevelsEvent.getContent();
    }

    // Min Required Power Level to remove user
    let requiredLevel = 50;
    if (isNumber(powerLevels[action])) {
        requiredLevel = powerLevels[action];
    }

    return powerLevel >= requiredLevel;
};

const canIKick = (roomMember: RoomMember | null, myPowerLevel: number, powerLevel: number, room: Room) => {
    return (
        roomMember?.membership === 'join' &&
        hasSufficientPowerLevelFor('kick', myPowerLevel, room) &&
        powerLevel < myPowerLevel
    );
};
const getDMRoomId = (directs, userId) => {
    const directsWithSelectedUser = directs.filter((direct) =>
        Object.keys(direct.currentState.members).includes(userId),
    );
    return directsWithSelectedUser[0]?.roomId;
};

export {
    getBaseUrl,
    getUsername,
    getUsernameOfRoomMember,
    isRoomAliasAvailable,
    doesRoomHaveUnread,
    isMedia,
    isNumber,
    isMe,
    canIKick,
    getPowerLabel,
    getDMRoomId,
};
